<template>
  <div>
    <backlinks-summary
      :meta="data.meta"
      :campaign="data"
      @change-filter-value="changeFilterValue($event)"
    >
    </backlinks-summary>
    <el-divider />

    <backlinks-grid
      ref="backlinksGrid"
      v-loading="isLoading"
      :data="backlinks"
      :key="admin"
      :admin="admin"
      @show-edit-backlink-dialog="showEditBacklinkDialog($event)"
      @delete-backlink="callAPI($event)"
      @validate-backlink="callAPI($event)"
      @update-status-backlink="callAPI($event, true)"
      @selection-changed-grid="selectionChanged($event)"
      @show-brief="showBriefDialog($event)"
      @show-text="showTextDialog($event)"
      @grid-columns-controller="fetchColumnsController($event)"
    >
      <template v-slot:entityButton>
        <div class="d-flex flex-row">
          <div>
            <grid-columns-button
              @update-grid-columns="updateGridColumns($event)"
              @open-column-visible-list="loadColumnsListFromController()"
              :data="gridColumns"
            >
            </grid-columns-button>
          </div>
          <add-entity-button
            v-if="
              data &&
              data.links &&
              data.links.backlinks &&
              data.links.backlinks.create
            "
            @click="showAddBacklinkDialog()"
            @open-bulk-change-dialog="showBulkChangeDialog()"
            :message="$t('addBacklink')"
            :disabledBulkButton="disabledBulkButton"
          >
          </add-entity-button>
        </div>
      </template>
      <template v-slot:exportButton>
        <el-button
          v-if="
            data &&
            data.links &&
            data.links.backlinks &&
            data.links.backlinks.export
          "
          style="margin-left: 1rem"
          type="primary"
          plain
          size="small"
          :loading="isLoadingExport"
          @click="exportBacklinks()"
        >
          <i class="fas fa-file-excel"></i> {{ $t("export") }}
        </el-button>
      </template>
    </backlinks-grid>
    <add-entity-dialog
      v-if="
        data &&
        data.links &&
        data.links.backlinks &&
        data.links.backlinks.create
      "
      v-model="addBacklinkDialogVisible"
      :link="data.links.backlinks.create"
      :title="$t('addBacklink')"
      @add-entity="callAPI($event)"
      :default-search-engine="defaultSearchEngine"
    >
    </add-entity-dialog>
    <edit-dialog
      v-if="actualBacklink && actualBacklink.update"
      :key="actualBacklink.update.href"
      v-model="editBacklinkDialogVisible"
      :data="actualBacklink.update"
      @update-backlink="callAPI($event)"
      :default-search-engine="defaultSearchEngine"
    >
    </edit-dialog>
    <brief-dialog
      v-if="briefDialogVisible"
      v-model="briefDialogVisible"
      :backlink="actualBacklink"
    >
    </brief-dialog>
    <text-dialog
      v-if="textDialogVisible"
      v-model="textDialogVisible"
      :data="actualBacklink"
      @update-backlink="updateBacklink($event)"
    >
    </text-dialog>
    <bulk-change-dialog
      v-if="bulkUpdate"
      v-model="bulkChangeDialogVisible"
      :bulkUpdate="bulkUpdate"
      @bulk-update-call="updateBulk($event)"
      :isContent="false"
    >
    </bulk-change-dialog>
  </div>
</template>

<script>
import BacklinksGrid from "@/pages/CampaignManager/Backlinks/Grid";
import BacklinksSummary from "@/pages/CampaignManager/Backlinks/BacklinksSummary";
import AddEntityDialog from "@/components/CampaignManager/AddEntityDialog";
import EditDialog from "@/pages/CampaignManager/Backlinks/EditDialog";
import BriefDialog from "@/pages/CampaignManager/Backlinks/BriefDialog";
import TextDialog from "@/pages/CampaignManager/Backlinks/TextDialog";
import AddEntityButton from "@/components/CampaignManager/AddEntityButton";
import { errorHandlerMixin } from "@/mixins/errorHandlerMixin.js";
import {isSameDomainNameMixin} from "@/mixins/isSameDomainNameMixin.js"
import BulkChangeDialog from "@/components/CampaignManager/BulkChangeDialog";
import GridColumnsButton from "@/components/CampaignManager/GridColumnsButton";
import { formatDateWithDashes } from "@/javascripts/formatDate";
import { DateTime } from "luxon";

export default {
  mixins: [errorHandlerMixin,isSameDomainNameMixin],
  components: {
    BacklinksGrid,
    BacklinksSummary,
    EditDialog,
    BriefDialog,
    AddEntityButton,
    AddEntityDialog,
    TextDialog,
    BulkChangeDialog,
    GridColumnsButton,
  },
  props: {
    data: Object,
    admin: Boolean,
    studyUrl: String,
    defaultSearchEngine: Object,
    account: Object,
    readCampaign: Object,
    bulkUpdate: Object,
  },
  data() {
    return {
      backlinks: [],
      addBacklinkDialogVisible: false,
      briefDialogVisible: false,
      textDialogVisible: false,
      editBacklinkDialogVisible: false,
      actualBacklink: null,
      isLoading: false,
      urlsMetricsData: [],
      netlinkingMetricHistory: [],
      isLoadingExport: false,
      columnController: null,
      gridColumns: null,
      bulkChangeDialogVisible: false,
      selectedBacklinks: [],
    };
  },
  methods: {
    selectionChanged(rows) {
      this.selectedBacklinks = rows.map((element) => element.id);
    },
    showBulkChangeDialog() {
      this.bulkChangeDialogVisible = true;
    },
    updateBulk(data) {
      const request = {
        data: {
          type: "BACKLINKS_BULK_UPDATE",
          attributes: {
            backlinksIds: this.selectedBacklinks,
          },
        },
      };
      if (data.status) request.data.attributes.status = data.status;
      if (data.dateStatusDomainToValidate)
        request.data.attributes.dateStatusDomainToValidate =
          data.dateStatusDomainToValidate;
      if (data.redactionPrice) request.data.attributes.redactionPrice = data.redactionPrice;
      if (data.reasonOfDelay)
        request.data.attributes.reasonOfDelay = data.reasonOfDelay;
      if (data.detailsOfDelay)
        request.data.attributes.detailsOfDelay = data.detailsOfDelay;
      if (data.reasonOfRefusal)
        request.data.attributes.reasonOfRefusal = data.reasonOfRefusal;
      if (data.detailsOfRefusal)
        request.data.attributes.detailsOfRefusal = data.detailsOfRefusal;

      this.$api[this.bulkUpdate.method.toLowerCase()](
        this.bulkUpdate.href,
        request
      )
        .then((_) => {
          this.fetchBacklinks();
          this.refreshMeta();
        })
        .catch((error) => {
          this.handleError(error);
          console.error(error);
        });
    },
    loadColumnsListFromController() {
      if (this.columnController)
        this.gridColumns = this.columnController.gridColumns
          .filter((column) => column.colDef.field !== "selector")
          .map((filteredColumn) => {
            return {
              colId: filteredColumn.colId,
              headerName: filteredColumn.colDef.headerName,
              visible: filteredColumn.visible,
            };
          });
    },
    fetchColumnsController(columnController) {
      this.columnController = columnController;
      this.loadColumnsListFromController();
    },
    updateGridColumns(data) {
      if (data.column === "all") {
        let allColumnsIds = this.columnController.gridColumns.map(
          (column) => column.colId
        );
        this.columnController.setColumnsVisible(allColumnsIds, data.isVisible);
      } else
        this.columnController.setColumnVisible(data.column, data.isVisible);

      this.loadColumnsListFromController();
    },
    changeFilterValue(value) {
      this.$refs.backlinksGrid.setFilter(value);
    },
    callAPI(details, isUpdateStatus = false) {
      if (!isUpdateStatus) {
        this.isLoading = true;
      }
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          this.isLoading = false;
          this.fetchBacklinks();
          this.$emit("refresh-campaign");
          if (isUpdateStatus) {
            this.refreshMeta();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
          console.error(error);
        });
    },
    updateBacklink(details) {
      this.isLoading = true;
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          // this.actualBacklink = response.data.data
          this.fetchBacklinks();
          this.refreshMeta();
          this.isLoading = false;
          this.$message({
            message: this.$i18n.t("backlinkUpdated"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
          console.error(error);
        });
    },
    fillBacklinkWithUrlMetrics(backlink, urlMetrics) {
      if (urlMetrics.metrics) {
        this.$set(backlink.sourceDomain, "metrics", {
          domainAuthority: urlMetrics.metrics.domainAuthority,
          trustFlow: urlMetrics.metrics.trustFlow,
          citationFlow: urlMetrics.metrics.citationFlow,
          referringDomainsWithEquity:
            urlMetrics.metrics.referringDomainsWithEquity,
          organicTraffic: urlMetrics.metrics.organicTraffic,
        });
        this.$set(backlink.sourceDomain, "classification", {
          majestic: urlMetrics.classification.majestic,
        });
      }
    },
    fillBacklinkWithUrlMetricsHistory(backlink, urlMetricsHistory) {
      if (urlMetricsHistory.history) {
        this.$set(backlink.targetUrl, "history", urlMetricsHistory.history);
      }
    },
    refreshMeta() {
      this.$api[this.readCampaign.method.toLowerCase()](
        this.readCampaign.href
      ).then((response) => {
        this.$emit("refresh-meta", response.data.data.meta);
      });
    },
    fetchBacklinks() {
      this.isLoading = true;
      if (
        this.data.links &&
        this.data.links.backlinks &&
        this.data.links.backlinks.list
      ) {
        this.$api
          .get(this.data.links.backlinks.list.href)
          .then((response) => {
            this.backlinks = response.data.data;
            this.backlinks.forEach(
              (backlink) =>
                (backlink.sourceDomain = { url: backlink.sourceDomain })
            );
            this.backlinks.forEach(
              (backlink) => (backlink.targetUrl = { url: backlink.targetUrl })
            );

            this.isLoading = false;

            ///// URLs METRICS //////
            let requestUrlMetrics = {
              urls: this.backlinks.map((backlink) => {
                return { url: backlink.sourceDomain.url, type: "DOMAIN" };
              }),
              minDate: formatDateWithDashes(lastMonthDate()),
              isoCountryCode: this.defaultSearchEngine.isoCountryCode,
            };
            this.$api
              .post(`/netlinking/urls/metrics`, { ...requestUrlMetrics })
              .then((response) => {
                this.urlsMetricsData = response.data.data;
                this.backlinks.forEach((backlink) => {
                  this.urlsMetricsData.forEach((urlMetricsData) => {

                    let isTheSameDomain = false;
                    let domain = backlink.sourceDomain.url;
                    if(domain){
                      if(!domain.startsWith("http"))
                        domain = "https://"+domain;
                      domain = new URL(domain).hostname;
                      isTheSameDomain = this.isSameDomainName(urlMetricsData.url.url, domain);
                  }
                    if (isTheSameDomain && urlMetricsData.url.url !== "") {
                      this.fillBacklinkWithUrlMetrics(backlink, urlMetricsData);
                    }
                  });
                });
              })
              .catch((error) => {
                console.error(error);
              });

            ///// URLs METRICS HISTORY //////
            const backlinksToFetch = this.backlinks.filter(
              (backlink) => backlink.status === "PUBLISHED"
            );
            let minTs = getMondayOfCurrentWeek().ts
            if(backlinksToFetch.length > 0){
              minTs = Math.min.apply(
                null,
                backlinksToFetch.map((backlink) => {
                  if (backlink.validationDate)
                    return DateTime.fromISO(backlink.validationDate,{}).ts;
                  else return getMondayOfCurrentWeek().ts;
                })
              );
            }
            const minDate = DateTime.fromMillis(minTs).toUTC();
            const endDate = DateTime.utc();

            let requestMetricsHistory = {
              urls: backlinksToFetch
                .filter(
                  (backlink) =>
                    backlink.targetUrl.url && backlink.targetUrl.url !== ""
                )
                .map((backlink) => {
                  return { url: backlink.targetUrl.url, type: "URL" };
                }),
              startDate: minDate
                ? formatDateWithDashes(minDate)
                : formatDateWithDashes(lastMonthDate()),
              endDate: formatDateWithDashes(endDate),
              isoCountryCode: this.defaultSearchEngine.isoCountryCode,
            };
            if (requestMetricsHistory.urls.length > 0) {
              this.$api
                .post(
                  `/studies/${this.account.id}/netlinking/urls/metrics/history`,
                  { ...requestMetricsHistory }
                )
                .then((response) => {
                  this.netlinkingMetricHistory = response.data.data;
                  this.backlinks.forEach((backlink) => {
                    this.netlinkingMetricHistory.forEach((metricsHistory) => {
                      if (
                        backlink.targetUrl.url.includes(
                          metricsHistory.url.url
                        ) &&
                        metricsHistory.url.url !== ""
                      ) {
                        this.fillBacklinkWithUrlMetricsHistory(
                          backlink,
                          metricsHistory
                        );
                      }
                    });
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    exportBacklinks() {
      if (this.data.links && this.data.links.backlinks.export) {
        const link = this.data.links.backlinks.export;
        this.isLoadingExport = true;
        this.$api[link.method.toLowerCase()](link.href, {
          headers: { Accept: link.acceptHeader },
        })
          .then((response) => {
            this.isLoadingExport = false;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "backlinks.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.$message({
              type: "success",
              duration: 6000,
              message: this.$i18n.t("exportSuccess"),
            });
          })
          .catch((error) => {
            this.isLoadingExport = false;
            console.erro(error);
            this.$message({
              type: "error",
              duration: 6000,
              message: this.$i18n.t("exportFailed"),
            });
          });
      }
    },
    showAddBacklinkDialog() {
      this.addBacklinkDialogVisible = true;
    },
    showEditBacklinkDialog(details) {
      this.actualBacklink = details;
      this.editBacklinkDialogVisible = true;
    },
    showBriefDialog(backlink) {
      this.actualBacklink = this.backlinks.find((bl) => bl.id === backlink.id);
      this.briefDialogVisible = true;
    },
    showTextDialog(backlink) {
      this.actualBacklink = this.backlinks.find((bl) => bl.id === backlink.id);
      this.textDialogVisible = true;
    },
  },
  mounted() {
    this.fetchBacklinks();
  },
  computed: {
    disabledBulkButton() {
      return this.selectedBacklinks.length <= 0;
    },
  },
  watch: {
    backlinks() {
      this.$emit("backlinks", this.backlinks);
      if (this.actualBacklink) {
        this.actualBacklink = this.backlinks.find(
          (bl) => bl.id === this.actualBacklink.id
        );
      }
    },
  },
};

function lastMonthDate() {
  return DateTime.utc().minus({ month: 1 });
}
function getMondayOfCurrentWeek() {
  return DateTime.utc().startOf("week");
}
</script>

<style lang="scss" scoped></style>
<i18n>
{
  "en": {
    "addBacklink": "Add a backlink",
    "export": "Export",
    "exportFailed": "Backlinks export has failed",
    "exportSuccess": "Backlinks have been downloaded",
    "backlinkUpdated": "Backlink updated"
  },
  "fr": {
    "addBacklink": "Ajouter un backlink",
    "export": "Exporter",
    "exportFailed": "L'export des backlinks a échoué",
    "exportSuccess": "Les backlinks ont été exportés",
    "backlinkUpdated": "Backlink mis à jour"
  },
  "de": {
    "addBacklink": "Füge einen Backlink hinzu",
    "export": "Exporteur",
    "exportFailed": "Backlinks konnten nicht exportiert werden",
    "exportSuccess": "Backlinks wurden exportiert",
    "backlinkUpdated": "Aktualisierter Backlink"
  }
}
</i18n>
