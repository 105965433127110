<template>
  <li
    class="nav-item pl-4"
    v-if="dimensionsByKeywordsLinkExists"
  >
    <a
      :href="dimensionsByKeywordsSpaLink"
      class="nav-link"
    >
      {{ $t("segmentationByKeywords") }}
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dimensionsByKeywordsSpaLink: null,
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
    dimensionsByKeywordsLinkExists() {
      return this.studyLinks["keywordSegmentation"];
    },
  },
  mounted() {
    this.dimensionsByKeywordsSpaLink = `/spa/to?redirect_uri=/studies/${this.study["id"]}/configuration?tab=dimensionsByKeywords`;
  },
};
</script>

<i18n>
{
  "en": {
    "segmentationByKeywords": "Segmentation by keywords"
  },
  "fr": {
    "segmentationByKeywords": "Segmentation par mots clés"
  },
  "de": {
    "segmentationByKeywords": "Segmentierung nach Schlüsselwörtern"
  }
}
</i18n>
