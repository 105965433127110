var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('backlinks-summary',{attrs:{"meta":_vm.data.meta,"campaign":_vm.data},on:{"change-filter-value":function($event){return _vm.changeFilterValue($event)}}}),_vm._v(" "),_c('el-divider'),_vm._v(" "),_c('backlinks-grid',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],key:_vm.admin,ref:"backlinksGrid",attrs:{"data":_vm.backlinks,"admin":_vm.admin},on:{"show-edit-backlink-dialog":function($event){return _vm.showEditBacklinkDialog($event)},"delete-backlink":function($event){return _vm.callAPI($event)},"validate-backlink":function($event){return _vm.callAPI($event)},"update-status-backlink":function($event){return _vm.callAPI($event, true)},"selection-changed-grid":function($event){return _vm.selectionChanged($event)},"show-brief":function($event){return _vm.showBriefDialog($event)},"show-text":function($event){return _vm.showTextDialog($event)},"grid-columns-controller":function($event){return _vm.fetchColumnsController($event)}},scopedSlots:_vm._u([{key:"entityButton",fn:function(){return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_c('grid-columns-button',{attrs:{"data":_vm.gridColumns},on:{"update-grid-columns":function($event){return _vm.updateGridColumns($event)},"open-column-visible-list":function($event){return _vm.loadColumnsListFromController()}}})],1),_vm._v(" "),(
            _vm.data &&
            _vm.data.links &&
            _vm.data.links.backlinks &&
            _vm.data.links.backlinks.create
          )?_c('add-entity-button',{attrs:{"message":_vm.$t('addBacklink'),"disabledBulkButton":_vm.disabledBulkButton},on:{"click":function($event){return _vm.showAddBacklinkDialog()},"open-bulk-change-dialog":function($event){return _vm.showBulkChangeDialog()}}}):_vm._e()],1)]},proxy:true},{key:"exportButton",fn:function(){return [(
          _vm.data &&
          _vm.data.links &&
          _vm.data.links.backlinks &&
          _vm.data.links.backlinks.export
        )?_c('el-button',{staticStyle:{"margin-left":"1rem"},attrs:{"type":"primary","plain":"","size":"small","loading":_vm.isLoadingExport},on:{"click":function($event){return _vm.exportBacklinks()}}},[_c('i',{staticClass:"fas fa-file-excel"}),_vm._v(" "+_vm._s(_vm.$t("export"))+"\n      ")]):_vm._e()]},proxy:true}])}),_vm._v(" "),(
      _vm.data &&
      _vm.data.links &&
      _vm.data.links.backlinks &&
      _vm.data.links.backlinks.create
    )?_c('add-entity-dialog',{attrs:{"link":_vm.data.links.backlinks.create,"title":_vm.$t('addBacklink'),"default-search-engine":_vm.defaultSearchEngine},on:{"add-entity":function($event){return _vm.callAPI($event)}},model:{value:(_vm.addBacklinkDialogVisible),callback:function ($$v) {_vm.addBacklinkDialogVisible=$$v},expression:"addBacklinkDialogVisible"}}):_vm._e(),_vm._v(" "),(_vm.actualBacklink && _vm.actualBacklink.update)?_c('edit-dialog',{key:_vm.actualBacklink.update.href,attrs:{"data":_vm.actualBacklink.update,"default-search-engine":_vm.defaultSearchEngine},on:{"update-backlink":function($event){return _vm.callAPI($event)}},model:{value:(_vm.editBacklinkDialogVisible),callback:function ($$v) {_vm.editBacklinkDialogVisible=$$v},expression:"editBacklinkDialogVisible"}}):_vm._e(),_vm._v(" "),(_vm.briefDialogVisible)?_c('brief-dialog',{attrs:{"backlink":_vm.actualBacklink},model:{value:(_vm.briefDialogVisible),callback:function ($$v) {_vm.briefDialogVisible=$$v},expression:"briefDialogVisible"}}):_vm._e(),_vm._v(" "),(_vm.textDialogVisible)?_c('text-dialog',{attrs:{"data":_vm.actualBacklink},on:{"update-backlink":function($event){return _vm.updateBacklink($event)}},model:{value:(_vm.textDialogVisible),callback:function ($$v) {_vm.textDialogVisible=$$v},expression:"textDialogVisible"}}):_vm._e(),_vm._v(" "),(_vm.bulkUpdate)?_c('bulk-change-dialog',{attrs:{"bulkUpdate":_vm.bulkUpdate,"isContent":false},on:{"bulk-update-call":function($event){return _vm.updateBulk($event)}},model:{value:(_vm.bulkChangeDialogVisible),callback:function ($$v) {_vm.bulkChangeDialogVisible=$$v},expression:"bulkChangeDialogVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }