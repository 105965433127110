<template>
  <div v-loading="isLoadingContents">
    <content-summary
      :meta="meta"
      :campaign="campaign"
      @change-filter-value="changeFilterValue($event)"
    >
    </content-summary>
    <el-divider />

    <contents-grid
      ref="contentsGrid"
      :data="contents"
      :is-admin="isAdmin"
      @delete-content="callAPI($event)"
      @show-edit-content-dialog="showEditContentDialog($event)"
      @show-text-dialog="showTextDialog($event)"
      @show-brief-dialog="showBriefDialog($event)"
      @selection-changed-grid="selectionChanged($event)"
      @grid-columns-controller="fetchColumnsController($event)"
    >
      <template v-slot:entityButton>
        <div class="d-flex flex-row">
          <div>
            <grid-columns-button
              @update-grid-columns="updateGridColumns($event)"
              @open-column-visible-list="loadColumnsListFromController()"
              :data="gridColumns"
            >
            </grid-columns-button>
          </div>
          <add-entity-button
            v-if="links && links.create && bulkUpdate"
            @click="showAddContentDialog()"
            @open-bulk-change-dialog="showBulkChangeDialog()"
            @open-column-visible-list="loadColumnsListFromController()"
            @update-grid-columns="updateGridColumns($event)"
            :message="$t('addContent')"
            :data="gridColumns"
            :disabledBulkButton="disabledBulkButton"
          >
          </add-entity-button>
        </div>
      </template>
    </contents-grid>
    <el-divider />
    <add-content-dialog
      v-if="links && links.create && addContentDialogVisible"
      v-model="addContentDialogVisible"
      :title="$t('addContent')"
      :link="links.create"
      @add-entity="callAPI($event)"
    >
    </add-content-dialog>
    <edit-content-dialog
      v-if="editContentLink && editContentDialogVisible"
      v-model="editContentDialogVisible"
      :data="editContentLink.link"
      @update-content="callAPI($event)"
    >
    </edit-content-dialog>
    <text-dialog
      v-if="contentDialog"
      v-model="textDialogVisible"
      :text="contentDialog"
      :is-loading-text="isLoadingText"
      @update-text="updateContentText($event)"
      @update-content="updateContent($event)"
    >
    </text-dialog>
    <brief-dialog
      v-if="contentDialog && briefDialogVisible"
      v-model="briefDialogVisible"
      :content="contentDialog"
      @refresh-content="refreshContent($event)"
      @update-content="updateContent($event)"
    >
    </brief-dialog>
    <bulk-change-dialog
      v-if="bulkUpdate"
      v-model="bulkChangeDialogVisible"
      :bulkUpdate="bulkUpdate"
      @bulk-update-call="updateBulk($event)"
      :isContent="true"
    >
    </bulk-change-dialog>
  </div>
</template>

<script>
import ContentsGrid from "@/pages/ContentManager/Contents/Grid";
import AddContentDialog from "@/pages/ContentManager/Contents/AddDialog";
import AddEntityButton from "@/components/CampaignManager/AddEntityButton";
import EditContentDialog from "@/pages/ContentManager/Contents/EditDialog";
import TextDialog from "@/pages/ContentManager/Contents/TextDialog";
import BriefDialog from "@/pages/ContentManager/Contents/BriefDialog";
import ContentSummary from "@/pages/ContentManager/Contents/ContentSummary";
import { errorHandlerMixin } from "@/mixins/errorHandlerMixin.js";
import BulkChangeDialog from "@/components/CampaignManager/BulkChangeDialog";
import GridColumnsButton from "@/components/CampaignManager/GridColumnsButton";

export default {
  mixins: [errorHandlerMixin],
  components: {
    ContentsGrid,
    AddContentDialog,
    AddEntityButton,
    EditContentDialog,
    TextDialog,
    BriefDialog,
    ContentSummary,
    BulkChangeDialog,
    GridColumnsButton,
  },
  props: {
    data: Array,
    links: Object,
    readCampaign: Object,
    bulkUpdate: Object,
    meta: Object,
    campaign: Object,
    isAdmin: Boolean,
  },
  data() {
    return {
      addContentDialogVisible: false,
      editContentDialogVisible: false,
      editContentLink: null,
      textDialogVisible: false,
      briefDialogVisible: false,
      contentDialog: null,
      isLoadingContents: false,
      isLoadingText: false,
      isLoadingBrief: false,
      contents: [],
      // meta: null,
      bulkChangeDialogVisible: false,
      selectedContents: [],
      columnController: null,
      gridColumns: null,
    };
  },
  methods: {
    loadColumnsListFromController() {
      if (this.columnController)
        this.gridColumns = this.columnController.gridColumns
          .filter((column) => column.colDef.field != "selector")
          .map((filteredColumn) => {
            return {
              colId: filteredColumn.colId,
              headerName: filteredColumn.colDef.headerName,
              visible: filteredColumn.visible,
            };
          });
    },
    fetchColumnsController(columnController) {
      this.columnController = columnController;
      this.loadColumnsListFromController();
    },
    updateGridColumns(data) {
      if (data.column == "all") {
        let allColumnsIds = this.columnController.gridColumns.map(
          (column) => column.colId
        );
        this.columnController.setColumnsVisible(allColumnsIds, data.isVisible);
      } else
        this.columnController.setColumnVisible(data.column, data.isVisible);

      this.loadColumnsListFromController();
    },
    changeFilterValue(value) {
      this.$refs.contentsGrid.setFilter(value);
    },
    callAPI(details) {
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          this.fetchContents();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchContents() {
      if (this.links && this.links.list) {
        this.isLoadingContents = true;
        this.$api[this.links.list.method.toLowerCase()](this.links.list.href)
          .then((response) => {
            this.contents = response.data.data;
            this.refreshMeta();
            this.isLoadingContents = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoadingContents = false;
          });
      }
    },
    refreshContent(content) {
      this.isLoadingText = true;
      const link = content.links.read;
      const givenContent = content;
      this.$api[link.method.toLowerCase()](link.href).then((response) => {
        const index = this.contents.findIndex(
          (ctt) => ctt.data.id == givenContent.data.id
        );
        let contentResponse = response.data;
        this.contentDialog = contentResponse;
        this.refreshMeta();
        this.$set(this.contents, index, contentResponse);
        this.isLoadingText = false;
      });
    },
    updateContentText(details) {
      this.isLoadingText = true;
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          const index = this.contents.findIndex(
            (content) => content.data.id == this.contentDialog.data.id
          );
          let content = this.contents[index];
          content.data.text = { ...response.data.data };
          this.contentDialog = content;
          this.refreshMeta();

          this.$set(this.contents, index, content);
          this.isLoadingText = false;
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("errorMessage"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
          this.isLoadingText = false;
        });
    },
    updateContent(details) {
      this.isLoadingText = true;
      this.$api[details.link.method.toLowerCase()](
        details.link.href,
        details.entity
      )
        .then((response) => {
          this.fetchContents();
          this.contentDialog = response.data;
          this.refreshMeta();
          this.isLoadingText = false;
          this.$message({
            message: this.$i18n.t("contentUpdated"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.isLoadingText = false;
          this.handleError(error);
        });
    },
    showAddContentDialog() {
      this.addContentDialogVisible = true;
    },
    showEditContentDialog(details) {
      this.editContentLink = details;
      this.editContentDialogVisible = true;
    },
    showTextDialog(content) {
      this.contentDialog = this.contents.find(
        (ctt) => ctt.data.id == content.id
      );
      this.textDialogVisible = true;
    },
    showBriefDialog(content) {
      this.contentDialog = this.contents.find(
        (ctt) => ctt.data.id == content.id
      );
      this.briefDialogVisible = true;
    },
    refreshMeta() {
      this.$api[this.readCampaign.method.toLowerCase()](
        this.readCampaign.href
      ).then((response) => {
        this.$emit("refresh-meta", response.data.meta);
      });
    },
    showBulkChangeDialog() {
      this.bulkChangeDialogVisible = true;
    },
    updateBulk(data) {
      const request = {
        contentsIds: this.selectedContents,
        publishDate: data.publishDate,
        deliveryDate: data.deliveryDate,
        status: data.status,
        creationDate: data.creationDate,
        costPerWord: data.costPerWord,
        costPerWordWithoutMargin: data.costPerWordWithoutMargin,
        writingType: data.writingType,
      };
      this.$api[this.bulkUpdate.method.toLowerCase()](
        this.bulkUpdate.href,
        request
      )
        .then((_) => {
          this.fetchContents();
        })
        .catch((error) => console.error(error));
    },
    selectionChanged(rows) {
      this.selectedContents = rows.map((element) => element.id);
    },
  },
  computed: {
    disabledBulkButton() {
      return this.selectedContents.length <= 0;
    },
  },
  mounted() {
    this.fetchContents();
  },
};
</script>

<i18n>
{
  "en": {
    "addContent": "Add a content",
    "errorMessage": "We encountered an error",
    "contentUpdated": "Content updated"
  },
  "fr": {
    "addContent": "Ajouter un contenu",
    "errorMessage": "Nous avons rencontré une erreur",
    "contentUpdated": "Contenu mis à jour"
  },
  "de": {
    "addContent": "Inhalt hinzufügen",
    "errorMessage": "Es ist ein Fehler aufgetreten",
    "contentUpdated": "Inhalt aktualisiert"
  }
}
</i18n>
