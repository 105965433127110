/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { LicenseManager } from "ag-grid-enterprise";
import "@/styles/ag-grid.scss";

import HighchartsOptions from "../javascripts/highchartsOptions";
import Vue from "vue/dist/vue.esm";
import VueI18n from "vue-i18n";
import api from "@/api";
import store from "@/store";
import ElementUI from "element-ui";
import "@/styles/font-awesome.scss";
import langEn from "element-ui/lib/locale/lang/en";
import langFr from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
import numeral from "numeral";

import App from "@/App";

// Components
import AdminMenu from "@/components/AdminMenu";
import AlertsConfigurator from "@/components/AlertsConfigurator";
import AppIntroduction from "@/components/AppIntroduction";
import BestKeywords from "@/components/BestKeywords";
import ContentManagerItem from "@/components/NavBar/ContentManagerItem";
import ContentSizeChart from "@/components/ContentSizeChart";
import ConversionLoader from "@/components/ConversionLoader";
import CrawlsItem from "@/components/NavBar/CrawlsItem";
import DimensionsConfigurator from "@/components/DimensionsConfigurator";
import DomainSearchChart from "@/components/DomainSearchChart";
import DomainSearchItem from "@/components/NavBar/DomainSearchItem";
import DueDateFilter from "@/components/task/filter/DueDate";
import EditorialBriefForm from "@/components/EditorialBriefForm";
import NavBar from "@/components/NavBar";
import NetlinkingItem from "@/components/NavBar/NetlinkingItem";
import NpsDialogWrapper from "@/components/nps/dialogWrapper";
import StatisticsChart from "@/components/admin/StatisticsChart";
import TaskCreationForm from "@/components/task/TaskCreationForm";
import TasksBoard from "@/components/task/TasksBoard";
import TrafficLoader from "@/components/TrafficLoader";
import DialogWrapper from "@/components/DialogWrapper";
import GoogleResultDialog from "@/components/GoogleResultDialog";
import ConversionMonitoring from "@/components/StudyConfiguration/ConversionMonitoring";
import NotFound from "@/components/errors/notFound";
import GoogleAnalyticsConnectManuallyButton from "@/components/admin/GoogleAnalytics/GaConnectManuallyButton.vue";
import GoogleAnalyticsView from "@/components/admin/GoogleAnalytics/GoogleAnalyticsView.vue";
import Favourites from "@/components/admin/Favourites.vue";
import GoogleSearchConsoleLink from "@/components/admin/GoogleSearchConsole/GoogleSearchConsoleLink.vue";
import GoogleAnalytics4Link from "@/components/admin/GoogleAnalytics4/GoogleAnalytics4Link";
import GoogleAccountLink from "@/components/admin/GoogleAccount/GoogleAccountLink.vue";
import GoogleAdsLink from "@/components/admin/GoogleAds/GoogleAdsLink.vue";
import GoogleAnalyticsLink from "@/components/admin/GoogleAnalytics/GoogleAnalyticsLink";
import DimensionsByPagesLink from "@/components/admin/Dimensions/DimensionsByPagesLink.vue";
import DimensionsByKeywordsLink from "@/components/admin/Dimensions/DimensionsByKeywordsLink.vue";
import EditConfigurationPageLinkRouter from "@/components/EditConfigurationPageLinkRouter.vue";
// Pages
import CompanyGroup from "@/pages/CompanyGroup";
import Company from "@/pages/Company";
import CompanyEdit from "@/pages/CompanyEdit";
import Conversion from "@/pages/Conversion";
import ContentManager from "@/pages/ContentManager";
import Crawls from "@/pages/Crawls";
import EditorialBrief from "@/pages/EditorialBrief";
import AdminOverview from "@/pages/AdminOverview";
import AdminTasks from "@/pages/AdminTasks";
import AdminTools from "@/pages/AdminTools";
import Alerts from "@/pages/Alerts";
import Tasks from "@/pages/Tasks";
import ActionPlan from "@/pages/ActionPlan";
import ActionPlanContent from "@/pages/ActionPlan/Content";
import ActionPlanSummary from "@/pages/ActionPlan/Summary";
import ActionPlanTechnicalPageSpeed from "@/pages/ActionPlan/Technical/pageSpeed.vue";
import Keyword from "@/pages/Keywords/Keyword";
import Keywords from "@/pages/Keywords";
import KeywordsSearch from "@/pages/KeywordsSearch";
import KeywordsImport from "@/pages/Keywords/Import";
import KeywordsImportByFile from "@/pages/Keywords/Import/ByFile";
import KeywordsImportNew from "@/pages/Keywords/Import/New";
import KeywordsImportExcel from "@/pages/Keywords/Import/ImportExcel/index.vue";
import Competitors from "@/pages/Competitors";
import Competitor from "@/pages/Competitor";
import CompetitorReportDetails from "@/pages/Report/Competitor";
import DomainSearch from "@/pages/DomainSearch";
import Netlinking from "@/pages/Netlinking";
import User from "@/pages/User";
import CampaignManager from "@/pages/CampaignManager";
import KeywordSearch from "@/pages/KeywordSearch";
import KeywordClassification from "@/pages/KeywordSearch/Classification";
import GoogleResultsPage from "@/pages/GoogleResults";
import GridImport from "@/pages/Keywords/Import/ImportExcel/GridImport";
import Summary from "@/pages/Summary";
import ConversionSummary from "@/pages/Summary/Conversion";
import TrafficSummary from "@/pages/Summary/Traffic";
import PositionsSummary from "@/pages/Summary/Positions";
import ShareOfVoiceSummary from "@/pages/Summary/ShareOfVoice";
import TaskPage from "@/pages/TaskPage";
import Traffic from "@/pages/Traffic";
import ContentTextPage from "@/pages/ContentTextPage";
import BacklinkTextPage from "@/pages/BacklinkTextPage";
import ContentBriefPage from "@/pages/ContentBriefPage";
import BacklinkBriefPage from "@/pages/BacklinkBriefPage";
import SpaFrom from "@/pages/SpaFrom";
import ContentTextPagePrivate from "@/pages/ContentTextPagePrivate";
import BacklinkTextPagePrivate from "@/pages/BacklinkTextPagePrivate";
import ContentBriefPagePrivate from "@/pages/ContentBriefPagePrivate";
import BacklinkBriefPagePrivate from "@/pages/BacklinkBriefPagePrivate";

import { messages } from "@/locales";

LicenseManager.setLicenseKey(
  "CompanyName=Elo-Solutions,LicensedApplication=SmartKeyword,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-017582,ExpiryDate=27_July_2022_[v2]_MTY1ODg3NjQwMDAwMA==fcca4deae6e637cb7067a6f5683ed72a"
);

HighchartsOptions.set();

I18nLocale === "fr" ? locale.use(langFr) : locale.use(langEn);
Vue.prototype.$ELEMENT = { size: "medium" };
Vue.prototype.$api = api;

Vue.use(VueI18n);
Vue.use(ElementUI);

Vue.component("admin-menu", AdminMenu);
Vue.component("alerts-configurator", AlertsConfigurator);
Vue.component("app-introduction", AppIntroduction);
Vue.component("best-keywords", BestKeywords);
Vue.component("content-manager-item", ContentManagerItem);
Vue.component("content-size-chart", ContentSizeChart);
Vue.component("conversion-loader", ConversionLoader);
Vue.component("crawls-item", CrawlsItem);
Vue.component("dimensions-configurator", DimensionsConfigurator);
Vue.component("domain-search-item", DomainSearchItem);
Vue.component("domain-search-schart", DomainSearchChart);
Vue.component("due-date-filter", DueDateFilter);
Vue.component("editorial-brief-form", EditorialBriefForm);
Vue.component("nav-bar", NavBar);
Vue.component("netlinking-item", NetlinkingItem);
Vue.component("nps-survey-dialog-wrapper", NpsDialogWrapper);
Vue.component("statistics-chart", StatisticsChart);
Vue.component("task-creation-form", TaskCreationForm);
Vue.component("tasks-board", TasksBoard);
Vue.component("traffic-loader", TrafficLoader);
Vue.component("dialog-wrapper", DialogWrapper);
Vue.component("google-result-dialog", GoogleResultDialog);

Vue.component("account-summary", Summary);
Vue.component("admin-overview", AdminOverview);
Vue.component("app", App);
Vue.component("content-manager", ContentManager);
Vue.component("conversion", Conversion);
Vue.component("company-group", CompanyGroup);
Vue.component("company", Company);
Vue.component("company-edit", CompanyEdit);
Vue.component("conversion-summary-report", ConversionSummary);
Vue.component("crawls", Crawls);
Vue.component("edittorial-brief", EditorialBrief);
Vue.component("traffic-summary-report", TrafficSummary);
Vue.component("positions-summary-report", PositionsSummary);
Vue.component("share-of-voice-summary-report", ShareOfVoiceSummary);
Vue.component("admin-tasks", AdminTasks);
Vue.component("admin-tools", AdminTools);
Vue.component("alerts", Alerts);
Vue.component("tasks", Tasks);
Vue.component("traffic", Traffic);
Vue.component("action-plan", ActionPlan);
Vue.component("action-plan-content", ActionPlanContent);
Vue.component("action-plan-summary", ActionPlanSummary);
Vue.component("page-speed", ActionPlanTechnicalPageSpeed);
Vue.component("keyword", Keyword);
Vue.component("keywords", Keywords);
Vue.component("keywords-import", KeywordsImport);
Vue.component("keywords-import-by-file", KeywordsImportByFile);
Vue.component("keywords-import-new", KeywordsImportNew);
Vue.component("keywords-import-excel", KeywordsImportExcel);
Vue.component("keywords-search", KeywordsSearch);
Vue.component("competitors", Competitors);
Vue.component("competitor", Competitor);
Vue.component("competitor-report-details", CompetitorReportDetails);
Vue.component("domain-search", DomainSearch);
Vue.component("netlinking", Netlinking);
Vue.component("user", User);
Vue.component("campaign-manager", CampaignManager);
Vue.component("keyword-search", KeywordSearch);
Vue.component("keyword-classification", KeywordClassification);
Vue.component("google-results-page", GoogleResultsPage);
Vue.component("grid-import", GridImport);
Vue.component("task-page", TaskPage);
Vue.component("content-text-page", ContentTextPage);
Vue.component("backlink-text-page", BacklinkTextPage);
Vue.component("content-brief-page", ContentBriefPage);
Vue.component("backlink-brief-page", BacklinkBriefPage);

Vue.component("conversion-monitoring", ConversionMonitoring);
Vue.component("not-found", NotFound);
Vue.component(
  "ga-connect-manually-button",
  GoogleAnalyticsConnectManuallyButton
);
Vue.component("google-analytics-view", GoogleAnalyticsView);
Vue.component("spa-from", SpaFrom);
Vue.component("favourites", Favourites);
Vue.component("google-search-console-link", GoogleSearchConsoleLink);
Vue.component("content-text-page-private", ContentTextPagePrivate);
Vue.component("backlink-text-page-private", BacklinkTextPagePrivate);
Vue.component("content-brief-page-private", ContentBriefPagePrivate);
Vue.component("backlink-brief-page-private", BacklinkBriefPagePrivate);
Vue.component("google-analytics4-link", GoogleAnalytics4Link);
Vue.component("google-account-link", GoogleAccountLink);
Vue.component("google-ads-link", GoogleAdsLink);
Vue.component("dimensions-by-pages-link", DimensionsByPagesLink);
Vue.component("dimensions-by-keywords-link", DimensionsByKeywordsLink);
Vue.component("google-analytics-link", GoogleAnalyticsLink);
Vue.component(
  "edit-configuration-page-link-router",
  EditConfigurationPageLinkRouter
);

// load a locale
numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});

// switch between locales
numeral.locale("fr");

Vue.filter("formatNumber", function (value) {
  if (value === "-") return "-";
  if (value === 0) return 0;
  if (!value) return "";
  if (value < 1000) {
    value = Math.round(value);
  } else {
    value = numeral(value).format("0.0a");
  }
  return value;
});

Vue.filter("formatNumberSigned", function (value) {
  if (value === 0) return "+0";
  if (!value) return "";
  if (value < 1000) {
    value = numeral(value).format("+0a");
  } else {
    value = numeral(value).format("+0.0a");
  }
  return value;
});

Vue.filter("formatNumberOrdinal", function (value) {
  if (value === 0) return 0;
  if (!value) return "";
  value = numeral(value).format("0o");
  return value;
});

Vue.filter("formatCurrency", function (value, language) {
  if (language === "fr") {
    if (value === 0) return "0 €";
    if (!value) return "";
    if (value < 1000) {
      value = `${Math.round(value)} €`;
    } else {
      value =
        Math.floor(value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " €";
    }
  } else {
    if (value === 0) return "€0";
    if (!value) return "";
    if (value < 1000) {
      value = `€${Math.round(value)}`;
    } else {
      value =
        "€" +
        Math.floor(value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
  }

  return value;
});

Vue.filter("formatCurrencyFloat", function (value, language) {
  if (language === "fr") {
    if (value === 0) return "0 €";
    if (!value) return "";
    return `${Number(value).toLocaleString("fr", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} €`;
  } else {
    if (value === 0) return "€0";
    if (!value) return "";
    return `€${Number(value).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
});

Vue.filter("formatNumberPercentage", function (value) {
  if (value === 0) return 0;
  if (!value) return "";
  value = numeral(value).format("0%");
  return value;
});

Vue.filter("formatDatePicker", function () {
  if (i18n.locale === "en") return "MM/dd/yyyy";
  else return "dd/MM/yyyy";
});

Vue.filter("formatDateFromLocale", function (string) {
  // Can't create a date format with '/' if the date is in French format 25/12/2023
  // So i start from the principle that the date is in French format
  // Then transform it to American format and create a new Date object
  // This Date object can then be manipulate by everyone how they want
  const dateSplit = string.split("/");
  dateSplit[0] = dateSplit.splice(1, 1, dateSplit[0])[0];

  const date = new Date(dateSplit.join("/"));

  if (i18n.locale === "en")
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  else return string;
});

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

const i18n = new VueI18n({
  messages,
  locale: I18nLocale,
  fallbackLocale: "en",
  silentTranslationWarn: true,
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    el: "#app",
    store,
    i18n,
    data() {
      return {
        studyId: null,
        userLocale: null,
      };
    },
    computed: {
      study() {
        return store.state.study;
      },
      studyLinks() {
        return store.state.studyLinks;
      },
    },
    beforeMount() {
      if (this.$el.attributes["study-id"])
        this.studyId = parseInt(this.$el.attributes["study-id"].value);
      if (this.$el.attributes["user-locale"]) {
        this.userLocale = this.$el.attributes["user-locale"].value;
      } else this.userLocale = this.getNavigatorLanguage();
    },
    mounted() {
      if (this.studyId) store.dispatch("fetchStudy", this.studyId);
      if (this.userLocale) store.dispatch("fetchUserLanguage", this.userLocale);
    },
    methods: {
      getNavigatorLanguage() {
        if (navigator.languages !== undefined) return navigator.languages[0];
        return navigator.language;
      },
    },
  });
});
